import { render, staticRenderFns } from "./Changelog.vue?vue&type=template&id=799ce26c&"
import script from "./Changelog.vue?vue&type=script&lang=js&"
export * from "./Changelog.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VAutocomplete,VBtn,VCol,VDataTable,VDatePicker,VMenu,VRow,VSpacer,VTextField})


/* hot reload */
if (module.hot) {
  var api = require("/home/azdevops/azdevops-agent/_work/1/s/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('799ce26c')) {
      api.createRecord('799ce26c', component.options)
    } else {
      api.reload('799ce26c', component.options)
    }
    module.hot.accept("./Changelog.vue?vue&type=template&id=799ce26c&", function () {
      api.rerender('799ce26c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/Changelog.vue"
export default component.exports